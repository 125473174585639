<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <v-card class="pl-5 pr-5">
      <v-card-text>
        <validation-observer v-slot="{ handleSubmit }">
          <v-form
            ref="form"
            lazy-validation
            @submit.prevent="handleSubmit(initialize)"
          >
            <v-row>
              <v-col>
                <v-card color="#AECBF2">
                  <v-card-text>
                    <v-row>
                      <v-col
                        class="align-center d-flex justify-end"
                        cols="3"
                      >
                        <div>
                          <h3 class="textLabel">
                            {{ $t("messages.job") }}
                          </h3>
                        </div>
                      </v-col>
                      <v-col cols="3">
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <v-select
                            v-model="job"
                            :items="listJob"
                            item-text="name"
                            item-value="id"
                            :no-data-text="$t('table.messages.no_data')"
                            :error-messages="errors[0]"
                          />
                        </validation-provider>
                      </v-col>
                      <v-col cols="3">
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <v-select
                            v-model="shift"
                            :items="listShift"
                            item-value="id"
                            :item-text="getNameShift"
                            :no-data-text="$t('table.messages.no_data')"
                            :error-messages="errors[0]"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                class="align-center d-flex"
                cols="1"
              >
                <div>
                  <h3 class="textLabelLight">
                    {{ $t("messages.period") }}
                  </h3>
                </div>
              </v-col>
              <v-col cols="4">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-select
                    v-model="selectedDate"
                    :items="dates"
                    item-text="name"
                    item-value="value"
                    :item-disabled="checkDate"
                    :multiple="assignType === 1"
                    :no-data-text="$t('table.messages.no_data')"
                    :error-messages="errors[0]"
                  />
                </validation-provider>
                <p
                  v-if="assignType === 1"
                  class="custom-color-note"
                >
                  ※同じ週間で出勤日をご選択ください。
                </p>
                <p
                  v-if="assignType === 2"
                  class="custom-color-note"
                >
                  ※一日のみご選択ください。
                </p>
              </v-col>
              <v-col
                class="align-center d-flex justify-end"
                cols="7"
              >
                <h3
                  v-if="itemsConvert.length > 0"
                  class="textShiftDecided"
                  @click="dialog = true"
                >
                  シフトは企画が決定された。>>>
                </h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <div class="lblText">
                  <h3 class="textLabelLight">
                    {{ $t("messages.commutingTime") }}
                  </h3>
                </div>
                <div class="align-self-center mr-9">
                  <input-date-picker-filter
                    :value="startAt"
                    prop="startAt"
                    :item="startAt"
                    :is-outlined="true"
                    @save="save"
                  />
                </div>
              </v-col>
              <v-col cols="3">
                <div class="lblText">
                  <h3 class="textLabelLight">
                    {{ $t("messages.leaveTime") }}
                  </h3>
                </div>
                <div class="align-self-center mr-9">
                  <input-date-picker-filter
                    :value="endAt"
                    prop="endAt"
                    :item="endAt"
                    :is-outlined="true"
                    @save="save"
                  />
                  <v-checkbox
                    v-model="checkboxEndAt"
                    class="checkboxEndAt"
                    label="ラスト"
                  />
                </div>
              </v-col>
              <v-col cols="2">
                <div class="lblText">
                  <h3 class="textLabelLight">
                    {{ $t("messages.breakTime") }}
                  </h3>
                </div>
                <div class="align-self-center mr-9">
                  <v-text-field
                    v-model="planBreakTime"
                    readonly
                    outlined
                  />
                </div>
              </v-col>
              <v-col cols="2">
                <div class="lblText">
                  <h3 class="textLabelLight">
                    {{ $t("messages.workingHour") }}
                  </h3>
                </div>
                <div class="align-self-center mr-9">
                  <v-text-field
                    v-model="workingHour"
                    readonly
                    outlined
                  />
                </div>
              </v-col>
              <v-col
                v-if="selectedItems.length === 0"
                cols="2"
                class="inputDisplay"
              >
                <v-btn
                  :disabled="itemsConvert.length > 0"
                  large
                  block
                  color="forth"
                  class="mb-5 pt-0 white--text inputDisplayBtn"
                  type="submit"
                >
                  <span class="font-weight-bold text-h5">
                    {{ $t("messages.display") }}
                  </span>
                </v-btn>
              </v-col>
              <v-col
                v-else
                cols="2"
                class="inputDisplay"
              >
                <v-btn
                  large
                  block
                  color="forth"
                  class="mb-5 pt-0 white--text inputDisplayBtn"
                  @click="dialogSwitch = true"
                >
                  <span class="font-weight-bold text-h5">
                    {{ $t("messages.display") }}
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </validation-observer>
        <v-row>
          <v-col cols="3">
            <div class="lblText">
              <h3 class="textLabelLight">
                {{ $t("messages.parttimeJobList") }}
              </h3>
            </div>
            <div class="align-self-center mr-9">
              <v-select
                v-model="parttimeJob"
                :items="parttimeJobList"
                item-text="name"
                item-value="value"
                outlined
                :no-data-text="$t('table.messages.no_data')"
              />
            </div>
          </v-col>
          <v-col cols="3">
            <div class="lblText">
              <h3 class="textLabelLight">
                {{ $t("messages.sex") }}
              </h3>
            </div>
            <div class="align-self-center mr-9">
              <v-select
                v-model="sex"
                :items="sexArray"
                item-text="name"
                item-value="value"
                outlined
                :no-data-text="$t('table.messages.no_data')"
              />
            </div>
          </v-col>
          <v-col cols="3">
            <div class="lblText">
              <h3 class="textLabelLight">
                {{ $t("messages.language") }}
              </h3>
            </div>
            <div class="align-self-center mr-9">
              <v-select
                v-model="language"
                :items="languages"
                item-text="name"
                item-value="value"
                outlined
              />
            </div>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-between align-center">
          <v-col
            cols="3"
            class="mb-9"
          >
            <div class="lblText">
              <h3 class="textLabelLight">
                {{ $t("table.headers.amountAssigned") }}
              </h3>
            </div>
            <div class="align-self-center mr-9">
              <v-text-field
                v-model="assignCount"
                readonly
                outlined
              />
            </div>
          </v-col>
          <v-col cols="9">
            <v-row class="d-flex justify-end">
              <v-col cols="6">
                <div class="align-self-center">
                  <v-text-field
                    id="search"
                    v-model="search"
                    name="search pa-0"
                    :placeholder="$t('placeholder.shiftAllotment')"
                    outlined
                    clearable
                    @keydown.enter="filterData"
                    @click:clear="filterData"
                  />
                </div>
              </v-col>
              <v-col cols="2">
                <div class="mb-9">
                  <v-btn
                    large
                    color="primary"
                    @click="filterData"
                  >
                    <span class="pr-5 pl-5 font-weight-bold text-h5">
                      {{ $t("table.messages.search") }}
                    </span>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <span
          v-if="isSelectAssgin"
          class="blue--text"
        >{{
          $t("messages.listSelectAssign")
        }}</span>
        <v-row
          v-if="isSelectAssgin"
          class="mb-5"
        >
          <v-col>
            <v-data-table
              v-model="selectedItems"
              :headers="headers"
              :items="selectedItems"
              :options.sync="optionsSelect"
              class="elevation-1"
              stle
              show-select
              :loading-text="$t('table.messages.loading')"
              :items-per-page="10"
              :footer-props="{
                itemsPerPageOptions: [5, 10, 15, 100],
                showFirstLastPage: false,
                'items-per-page-text': $t('table.messages.items_per_page'),
              }"
              :no-data-text="$t('table.messages.no_data')"
            >
              <template
                v-slot:footer.page-text
                class="mr-0"
              >
                {{ $t("table.messages.page") }}
                {{ optionsSelect.page }}
              </template>

              <template v-slot:item.index="props">
                {{ getIndexSelect(props.index) }}
              </template>

              <template v-slot:item.manageId="props">
                {{ getValueObject(props.item, "manageId") }}
              </template>

              <template v-slot:item.name="props">
                <span class="font-weight-bold textName">{{
                  getValueObject(props.item, "user.name")
                }}</span>
              </template>

              <template v-slot:item.furiganaName="props">
                <span class="font-weight-bold textName">{{
                  getValueObject(props.item, "furiganaName")
                }}</span>
              </template>

              <template v-slot:item.sex="props">
                {{
                  getValueObject(props.item, "sex")
                    ? $t("messages.male")
                    : $t("messages.female")
                }}
              </template>

              <template v-slot:item.residenceCardExpirationDate="props">
                {{
                  getDate(getValueObject(props.item, "residenceCardValidDate"))
                }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <span class="blue--text">{{ $t("messages.listAssign") }}</span>
        <v-row>
          <v-col>
            <v-data-table
              v-model="selectedItems"
              :headers="headers"
              :options.sync="options"
              :items="items"
              class="elevation-1"
              :search="search"
              stle
              show-select
              :loading-text="$t('table.messages.loading')"
              :loading="loading"
              :items-per-page="10"
              :footer-props="{
                itemsPerPageOptions: [5, 10, 15, 100],
                showFirstLastPage: false,
                'items-per-page-text': $t('table.messages.items_per_page'),
              }"
              :no-data-text="$t('table.messages.no_data')"
            >
              <template
                v-slot:footer.page-text
                class="mr-0"
              >
                {{ $t("table.messages.page") }}
                {{ options.page }}
              </template>

              <template v-slot:item.index="props">
                {{ getIndex(props.index) }}
              </template>

              <template v-slot:item.manageId="props">
                {{ getValueObject(props.item, "manageId") }}
              </template>

              <template v-slot:item.name="props">
                <span class="font-weight-bold textName">{{
                  getValueObject(props.item, "user.name")
                }}</span>
              </template>

              <template v-slot:item.furiganaName="props">
                <span class="font-weight-bold textName">{{
                  getValueObject(props.item, "furiganaName")
                }}</span>
              </template>

              <template v-slot:item.sex="props">
                {{
                  getValueObject(props.item, "sex")
                    ? $t("messages.male")
                    : $t("messages.female")
                }}
              </template>

              <template v-slot:item.residenceCardExpirationDate="props">
                {{
                  getDate(getValueObject(props.item, "residenceCardValidDate"))
                }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-end mt-3">
          <v-col
            cols="2"
            class="d-flex justify-space-between"
          >
            <v-btn
              :disabled="selectedItems.length === 0"
              large
              block
              color="accent"
              @click="updateSelectedShiftPeriod"
            >
              <span class="text-h5 pr-3 pl-3">
                {{ $t("messages.add") }}
              </span>
            </v-btn>
          </v-col>
          <v-col
            cols="2"
            class="d-flex justify-space-between"
          >
            <v-btn
              large
              block
              color="info"
              @click="cancel"
            >
              <span class="text-h5 pr-3 pl-3">{{ $t("messages.close") }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t("messages.close") }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="dialogSwitch"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="font-weight-bold text-h4">
          <v-row class="d-flex align-center justify-center">
            <v-col cols="8">
              {{ $t('messages.saveAssign') }}
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="text-h5"
            dark
            color="error"
            @click="initialize"
          >
            {{ $t('button.yes') }}
          </v-btn>
          <v-btn
            class="text-h5"
            @click="dialogSwitch = false"
          >
            {{ $t('button.cancel') }}
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog"
      width="70%"
    >
      <v-card>
        <v-card-text>
          <v-row class="mt-5">
            <v-col cols="3" />
            <v-col cols="6">
              <div class="headerDialog text-center">
                <h2>アルバイト割当企画</h2>
              </div>
            </v-col>
            <v-col cols="3" />
          </v-row>
          <v-row>
            <v-col>
              <v-card color="#AECBF2">
                <v-card-text>
                  <v-row>
                    <v-col
                      class="align-center d-flex justify-end"
                      cols="3"
                    >
                      <div>
                        <h3 class="textLabel">
                          {{ $t("messages.job") }}
                        </h3>
                      </div>
                    </v-col>
                    <v-col cols="3">
                      <v-select
                        v-model="job"
                        :items="listJob"
                        item-text="name"
                        readonly
                        item-value="id"
                        :no-data-text="$t('table.messages.no_data')"
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-select
                        v-model="shift"
                        :items="listShift"
                        readonly
                        item-value="id"
                        :item-text="getNameShift"
                        :no-data-text="$t('table.messages.no_data')"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              class="align-center d-flex"
              cols="1"
            >
              <div>
                <h3 class="textLabelLight">
                  {{ $t("messages.period") }}
                </h3>
              </div>
            </v-col>
            <v-col cols="4">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
              >
                <v-select
                  v-model="selectedDate"
                  :items="dates"
                  item-text="name"
                  item-value="value"
                  multiple
                  readonly
                  :no-data-text="$t('table.messages.no_data')"
                  :error-messages="errors[0]"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h3 class="textShift">
                ※相当企画を決定しなければならない。
              </h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                :headers="headersConvert"
                :options.sync="optionsConvert"
                :items="itemsConvert"
                class="elevation-1"
                stle
                :loading-text="$t('table.messages.loading')"
                :loading="loading"
                :items-per-page="10"
                :footer-props="{
                  itemsPerPageOptions: [5, 10, 15, 100],
                  showFirstLastPage: false,
                  'items-per-page-text': $t('table.messages.items_per_page'),
                }"
                :no-data-text="$t('table.messages.no_data')"
              >
                <template
                  v-slot:footer.page-text
                  class="mr-0"
                >
                  {{ $t("table.messages.page") }}
                  {{ options.page }}
                </template>

                <template v-slot:item.index="props">
                  {{ getIndex(props.index) }}
                </template>

                <template v-slot:item.manageId="props">
                  {{ getValueObject(props.item, "parttimeEmployeeManageId") }}
                </template>

                <template v-slot:item.name="props">
                  <span class="font-weight-bold textName">{{
                    getValueObject(props.item, "parttimeEmployeeName")
                  }}</span>
                </template>

                <template v-slot:item.scheduledAllocated="props">
                  <span
                    class="font-weight-bold"
                  >{{ getValueObject(props.item, "startAt") }} ~
                    {{ getValueObject(props.item, "endAt") }}</span>
                </template>

                <template v-slot:item.scheduledTime="props">
                  <span class="font-weight-bold">{{
                    getValueObject(props.item, "plannedDate")
                  }}</span>
                </template>

                <template v-slot:item.action="props">
                  <div>
                    <v-btn
                      class="mr-5"
                      color="#C9F7F5"
                      small
                      dark
                      @click="
                        updateItemConvert(getValueObject(props.item, 'id'))
                      "
                    >
                      {{ $t("messages.implementation") }}
                    </v-btn>
                    <v-btn
                      small
                      @click="
                        openDialogDelete(getValueObject(props.item, 'id'))
                      "
                    >
                      {{ $t("messages.cancel") }}
                    </v-btn>
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            large
            color="accent"
            @click="dialog = false"
          >
            {{ $t("messages.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="font-weight-bold text-h4">
          {{ $t("messages.deleteMessage") }}
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="text-h5"
            color="blue darken-1"
            text
            @click="closeDelete"
          >
            いいえ
          </v-btn>
          <v-btn
            class="text-h5"
            color="blue darken-1"
            text
            @click="deleteItemConfirm"
          >
            はい
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t("messages.close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import _ from 'lodash'
  import { get } from 'vuex-pathify'
  import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
  import { required, email, min } from 'vee-validate/dist/rules'
  import moment from 'moment'
  import InputDatePickerFilter from '../../components/app/InputDatePickerFiler'

  export default {
    name: 'ShiftAllotment',
    components: {
      ValidationProvider,
      ValidationObserver,
      InputDatePickerFilter,
    },
    data () {
      return {
        itemConvertId: '',
        dialog: false,
        dialogDelete: false,
        dialogSwitch: false,
        selectedItems: [],
        items: [],
        itemsConvert: [],
        search: '',
        headers: [
          {
            text: this.$t('table.headers.index'),
            align: 'start',
            value: 'index',
            class: 'font-weight-bold',
            sortable: false,
          },
          {
            text: this.$t('table.headers.manageId'),
            align: 'start',
            value: 'manageId',
            class: 'font-weight-bold',
            sortable: false,
          },
          {
            text: this.$t('table.headers.name'),
            align: 'start',
            value: 'name',
            class: 'font-weight-bold',
            sortable: false,
          },
          {
            text: this.$t('table.headers.furiganaName'),
            align: 'start',
            value: 'furiganaName',
            class: 'font-weight-bold',
            sortable: false,
          },
          {
            text: this.$t('table.headers.language'),
            align: 'start',
            value: 'language',
            class: 'font-weight-bold',
            sortable: false,
          },
          {
            text: this.$t('table.headers.sex'),
            align: 'start',
            value: 'sex',
            class: 'font-weight-bold',
            sortable: false,
          },
          {
            text: this.$t('table.headers.visaType'),
            align: 'start',
            value: 'visaType',
            class: 'font-weight-bold',
            sortable: false,
          },
          {
            text: this.$t('table.headers.residenceCardExpirationDate'),
            align: 'start',
            value: 'residenceCardExpirationDate',
            class: 'font-weight-bold',
            sortable: false,
          },
          {
            text: this.$t('table.headers.remainingWorkTime'),
            align: 'center',
            value: 'availableWorkHour',
            class: 'font-weight-bold',
            sortable: false,
          },
          {
            text: this.$t('table.headers.phoneNumber'),
            align: 'center',
            value: 'contactPhone',
            class: 'font-weight-bold',
            sortable: false,
          },
        ],
        headersConvert: [
          {
            text: this.$t('table.headers.index'),
            align: 'start',
            value: 'index',
            class: 'font-weight-bold',
            sortable: false,
            width: '8rem',
          },
          {
            text: this.$t('table.headers.manageId'),
            align: 'start',
            value: 'manageId',
            class: 'font-weight-bold',
            sortable: false,
            width: '8rem',
          },
          {
            text: this.$t('table.headers.name'),
            align: 'start',
            value: 'name',
            class: 'font-weight-bold',
            sortable: false,
            width: '16rem',
          },
          {
            text: this.$t('table.headers.scheduledAllocated'),
            align: 'center',
            value: 'scheduledAllocated',
            class: 'font-weight-bold',
            sortable: false,
            width: '12rem',
          },
          {
            text: this.$t('table.headers.scheduledTime'),
            align: 'center',
            value: 'scheduledTime',
            class: 'font-weight-bold',
            sortable: false,
            width: '8rem',
          },
          {
            text: this.$t('table.headers.action'),
            align: 'center',
            value: 'action',
            class: 'font-weight-bold',
            sortable: false,
          },
        ],
        options: {},
        optionsConvert: {},
        optionsSelect: {},
        loading: false,
        snackbarMessage: '',
        snackbar: false,
        dates: [],
        selectedDate: [],
        workingHour: '',
        startAt: '',
        endAt: '',
        startAtMenu: false,
        endAtMenu: false,
        parttimeJob: '',
        sex: null,
        job: '',
        shift: '',
        language: null,
        checkboxEndAt: false,
        itemCurrent: [],
        assignCount: null,
        parttimeJobList: [
          {
            value: '',
            name: '全体',
          },
          {
            value: true,
            name: '本工場のみ',
          },
        ],
        sexArray: [
          { value: null, name: '両方' },
          { value: true, name: '男' },
          { value: false, name: '女' },
        ],
        languages: [
          { value: null, name: '全体' },
          { value: 'ネパール', name: 'ネパール' },
          { value: 'ベトナム', name: 'ベトナム' },
          { value: '中央', name: '中央' },
          { value: '日本', name: '日本' },
          { value: 'all', name: '他国' },
        ],
        assignType: 1,
        planBreakTime: 0,
        isSelectAssgin: false,
        unSelectedItems: [],
      }
    },
    computed: {
      ...get('shift', [
        'periodShiftAssign',
        'selectedShiftPeriod',
        'message',
        'status',
        'error',
        'listAssigned',
      ]),
      listJob: get('job@list'),
      listShift: get('shift@shiftList.data.shifts'),
      listPlanConvert: get('plan@planConverts'),
    },
    watch: {
      search () {
        this.options.page = 1
      },
      error (value) {
        this.loading = false
        this.showSnackBar(value)
      },
      job (value) {
        this.$store.dispatch('shift/getShift', value)
      },
      selectedDate () {
        this.getPlanConvert()
      },
      shift (value) {
        const shift = this.listShift.find((item) => item.id === value)
        if (shift) {
          this.startAt = shift.shiftStartAt
          this.endAt = shift.shiftEndAt
          this.planBreakTime = shift.planBreakTime
        }
        this.getPlanConvert()
      },
      periodShiftAssign (value) {
        this.selectedShiftPeriod.forEach((item) => {
          value.forEach((value, index, object) => {
            if (value.manageId === item.parttimeEmployeeManageId) {
              value.availableWorkHour =
                value.availableWorkHour - item.workingHour
            }
            if (value.availableWorkHour < parseFloat(this.workingHour)) {
              object.splice(index, 1)
            }
          })
        })
        this.itemCurrent = value
        this.items = _.cloneDeep(this.itemCurrent)
        this.loading = false
      },
      items (value) {
        value.map((item) => {
          if (item.availableWorkHour === '168.00') {
            item.availableWorkHour = '40.00'
          }
          this.listAssigned.map((val) => {
            const dates = val.values.map(dates => { return dates.workdate })
            for (let index = 0; index < dates.length; index++) {
              const date = dates[index]
              if (item.manageId === val.parttimeEmployeeManageId && _.includes(this.selectedDate, date)) {
                this.selectedItems.push(item)
              }
            }
          })
        })
      },
      endAt () {
        this.getWorkingTime()
      },
      startAt () {
        this.getWorkingTime()
      },
      listPlanConvert (value) {
        this.itemsConvert = value
      },
      sex () {
        this.filterData()
      },
      language () {
        this.filterData()
      },
      parttimeJob () {
        this.filterData()
      },
      selectedItems (values) {
        this.isSelectAssgin = values.length > 0 ? values.length > 0 : 0
        if (!this.loading) {
          this.assignCount = +this.$route.query.totalDb + values.length
        }
        // values.map((value) => {
        //   this.items.splice(
        //     this.items.findIndex((item) => {
        //       return item.id === value.id
        //     }),
        //     1,
        //   )
        // })
      },
    },
    mounted () {
      if (this.$route.query.customerId) {
        this.$store.dispatch('job/getJobsCustomer', {
          id: this.$route.query.customerId,
        })
      }

      if (this.$route.query.assignType) {
        this.assignType = parseInt(this.$route.query.assignType)
      }

      if (this.$route.query.total) {
        this.assignCount = +this.$route.query.total
      }

      if (this.$route.query.dates) {
        this.dates = []
        const arrayDate = Array.isArray(this.$route.query.dates)
          ? this.$route.query.dates
          : [this.$route.query.dates]
        arrayDate.forEach((item) => {
          this.dates.push({
            name: item,
            value: item,
          })
        })
      }

      extend('required', {
        ...required,
        message: this.$t('validator.required'),
      })

      extend('email', {
        ...email,
        message: this.$t('validator.email_invalid'),
      })

      extend('number', {
        validate (value) {
          return /^\+?(0|[1-9]\d*)$/.test(value)
        },
        message: this.$t('validator.must_be_number'),
      })
      extend('min', {
        ...min,
        message: this.$t('validator.password_invalid'),
      })
      extend('date', {
        validate (value) {
          return moment(value, 'YYYY-mm-DD').format('YYYY-mm-DD') === value
        },
        message: this.$t('validator.invalid_date'),
      })
    },
    methods: {
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      save (payload) {
        if (this.startAt && this.endAt) {
          if (payload.prop === 'startAt') {
            const timeFirstStart = parseInt(payload.value.slice(0, 2))
            const timeLastStart = parseInt(payload.value.slice(3, 5))
            const timeFirstEnd = parseInt(this.endAt.slice(0, 2))
            const timeLastEnd = parseInt(this.endAt.slice(3, 5))
            if (
              this.checkActualAt(
                timeFirstStart,
                timeFirstEnd,
                timeLastStart,
                timeLastEnd,
              )
            ) {
              return
            }
            this.startAt = payload.value
          } else {
            const timeFirstStart = parseInt(this.startAt.slice(0, 2))
            const timeLastStart = parseInt(this.startAt.slice(3, 5))
            const timeFirstEnd = parseInt(payload.value.slice(0, 2))
            const timeLastEnd = parseInt(payload.value.slice(3, 5))
            if (
              this.checkActualAt(
                timeFirstStart,
                timeFirstEnd,
                timeLastStart,
                timeLastEnd,
              )
            ) {
              return
            }
            this.endAt = payload.value
          }
        } else {
          if (payload.prop === 'startAt') {
            this.startAt = payload.value
          } else {
            this.endAt = payload.value
          }
        }
      },
      checkActualAt (timeFirstStart, timeFirstEnd, timeLastStart, timeLastEnd) {
        let error = false
        if (timeFirstStart > timeFirstEnd) {
          this.showSnackBar('出勤時間は退勤時間より前である必要があります。')
          error = true
        } else if (
          timeFirstStart === timeFirstEnd &&
          timeLastStart > timeLastEnd
        ) {
          this.showSnackBar('出勤時間は退勤時間より前である必要があります。')
          error = true
        }
        return error
      },
      getPlanConvert () {
        const payload = {}
        if (this.shift) {
          payload.shiftId = this.shift
        }
        const date = Array.isArray(this.selectedDate)
          ? this.selectedDate
          : [this.selectedDate]

        if (this.selectedDate.length > 0) {
          payload.plannedDates = JSON.stringify(date)
        }

        if (this.shift && date.length > 0) {
          this.$store.dispatch('plan/getPlanConvert', payload)
        }
      },
      getDate (dateString) {
        return dateString ? moment.utc(dateString).format('YYYY-MM-DD') : ''
      },
      getValueObject (item, path, d = '') {
        return _.get(item, path, d)
      },
      convertNumber (str) {
        const timeStart = parseInt(str.slice(0, 2))
        const timeEnd = parseInt(str.slice(3, 5)) / 60
        return parseFloat(timeStart + timeEnd).toFixed(2)
      },
      convertTime (float) {
        let hour = parseInt(float)
        let minute = String(Math.round((float - parseInt(float)) * 60))

        if (hour.length === 1) {
          hour = '0' + hour
        }
        if (minute.length === 1) {
          minute = '0' + minute
        }

        return hour + ':' + minute
      },
      initialize () {
        this.items = []
        const startTime = this.convertNumber(this.startAt)
        let endTime = this.convertNumber(this.endAt)

        if (parseFloat(startTime) > parseFloat(endTime)) {
          endTime = parseFloat(endTime) + 24
        }

        const payload = {
          planStartAt: this.startAt,
          assignType: this.assignType,
          planEndAt: this.convertTime(endTime),
          dates: JSON.stringify(
            Array.isArray(this.selectedDate)
              ? this.selectedDate
              : [this.selectedDate],
          ),
          shiftId: this.shift,
          planBreakTime: this.planBreakTime,
        }
        if (this.planBreakTime === null) delete payload.planBreakTime
        if (this.search) {
          payload.keyword = this.search
        }
        this.dialogSwitch = false
        this.$store.dispatch('shift/getPeriodShiftAssign', payload)
        this.loading = true
        this.selectedItems = []
      },
      getNameShift (shift) {
        if (_.get(shift, 'shiftStartAt', '') === '') {
          return '00:00 ~ 00:00'
        }
        return (
          _.get(shift, 'shiftStartAt', '00:00') +
          ' ~ ' +
          _.get(shift, 'shiftEndAt', '00:00')
        )
      },
      getIndex (index) {
        return (this.options.page - 1) * this.options.itemsPerPage + 1 + index
      },
      getIndexSelect (index) {
        return (this.optionsSelect.page - 1) * this.optionsSelect.itemsPerPage + 1 + index
      },
      async updateSelectedShiftPeriod () {
        const dataSelected = []
        const dates = Array.isArray(this.selectedDate)
          ? this.selectedDate
          : [this.selectedDate]
        this.selectedItems.map((item) => {
          const dateSelect = []
          const data = {
            workingHour: this.workingHour * dates.length,
            parttimeEmployeeId: item.id,
            shiftId: this.shift,
            parttimeEmployeeName: item.user.name,
            parttimeEmployeeFurigana: item.furiganaName,
            parttimeEmployeeManageId: item.manageId,
            parttimeEmployeeSex: item.sex,
            parttimeEmployeeLanguage: item.language,
            parttimeEmployeeVisa: item.visaType,
          }

          const job = this.listJob.find((item) => item.id === this.job)

          if (job) {
            data.jobId = this.job
            data.jobName = job.name
          }

          const shift = this.listShift.find((item) => item.id === this.shift)

          if (shift) {
            data.shiftId = this.shift
            data.shiftStartAt = shift.shiftStartAt
            data.shiftEndAt = shift.shiftEndAt
            data.planBreakTime = shift.planBreakTime
          }
          dates.forEach((item) => {
            dateSelect.push({
              workdate: item,
              plannedStartAt: this.startAt,
              plannedEndAt: this.endAt,
              isLast: this.checkboxEndAt,
            })
          })

          data.values = dateSelect
          dataSelected.push(data)
        })
        if (this.job && this.shift && this.selectedItems.length > 0) {
          await this.$store.dispatch('shift/updateSelectedShiftPeriod', {
            selectedShiftPeriod: dataSelected,
          })

          await this.$router.push(
            '/shift-information-display/' + this.$route.params.id,
          )
        }
      },
      cancel () {
        this.$router.push('/shift-information-display/' + this.$route.params.id)
      },
      getWorkingTime () {
        if (!this.endAt || !this.startAt) return
        const startAt = this.convertNumber(this.startAt)
        let endAt = this.convertNumber(this.endAt)

        if (parseFloat(startAt) > parseFloat(endAt)) {
          endAt = parseFloat(endAt) + 24
        }

        this.workingHour = parseFloat(endAt - startAt - this.planBreakTime)
      },

      filterData () {
        this.options.page = 1
        let data = _.cloneDeep(this.itemCurrent)
        if (this.sex !== null) {
          data = data.filter((item) => item.sex === this.sex)
        }

        if (this.language) {
          if (this.language === 'all') {
            data = data.filter(
              (item) =>
                item.language !== 'ネパール' &&
                item.language !== 'ベトナム' &&
                item.language !== '中央' &&
                item.language !== '日本',
            )
          } else {
            data = data.filter((item) => item.language === this.language)
          }
        }

        if (this.parttimeJob !== '') {
          data = data.filter((item) => item.isExperienced === true)
        }

        if (this.search) {
          data = data.filter((item) => {
            const parttimeEmployeeName = item.parttimeEmployeeName
              .toLowerCase()
              .includes(this.search.trim().toLowerCase())
            const manageId = item.manageId
              .toString()
              .includes(this.search.trim().toLowerCase())
            if (parttimeEmployeeName || manageId) {
              return item
            }
          })
        }

        this.items = data
      },
      async deleteItemConfirm () {
        const payload = {
          id: this.itemConvertId,
        }
        await this.$store.dispatch('plan/delete', payload)
        await this.getPlanConvert()
        this.closeDelete()
      },
      openDialogDelete (value) {
        this.itemConvertId = value
        this.dialogDelete = true
      },
      async updateItemConvert (value) {
        const payload = {
          id: value,
          periodShiftId: this.$route.params.id,
        }

        await this.$store.dispatch('plan/update', payload).then(() => {
          this.getPlanConvert()
        })
      },
      closeDelete () {
        this.itemConvertId = ''
        this.dialogDelete = false
      },
      checkDate (value) {
        return moment(new Date()).diff(moment(value), 'day') < 0
      },
    },
  }
</script>

<style scoped lang="css">
.textLabel {
  color: #464e5f !important;
}

.textLabelLight {
  color: #5ca6d2;
}
.lblText {
  margin-top: 10px;
}
.inputDisplay {
  padding-top: calc(15px + 1.75rem);
}

.inputDisplayBtn {
  margin: 0px !important;
}
.checkboxEndAt {
  margin: 0px;
  padding: 0px;
}

.textName {
  color: #3699ff;
}

.textShiftDecided {
  text-decoration: underline;
  color: #1bc5bd;
  cursor: pointer;
}

.headerDialog {
  padding: 10px;
  border-radius: 34px;
  background-color: #b6edff;
  color: #464e5f;
}

.textShift {
  color: #1bc5bd;
}
.custom-color-note {
  color: #8da0e4;
}
</style>
